jQuery(document).ready(function ($) {

    AOS.init({
        'once': true
    });
        
    jQuery('.col-twins').matchHeight({
        byRow: true,
        property: 'height',
        target: null,
        remove: false
    });

    jQuery('.col-title').matchHeight({
        byRow: true,
        property: 'height',
        target: null,
        remove: false
    });

    jQuery('.col-post').matchHeight({
        byRow: true,
        property: 'height',
        target: null,
        remove: false
    });

    jQuery('.col-img').matchHeight({
        byRow: true,
        property: 'height',
        target: null,
        remove: false
    });
 
    jQuery('.col-menu').matchHeight({
        byRow: false,
        property: 'height',
        target: jQuery('#site-logo'),
        remove: false
    });



    // MOBILE MENU TOGGLE
    function animateMenu(element, action) {
        var $this = element;

        jQuery("#hamburger-icon").toggleClass('is-active');
        if(action == '') {
            jQuery("#side_menu_wrapper").toggleClass('open');
            if(!$this.hasClass("closed")) {
                TweenLite.to(jQuery("#side_menu_wrapper"), 0.5, {ease: Power4.easeInOut, x: '0%'});
                
                jQuery("#hamburger-icon").addClass("closed");
                jQuery("body").removeClass("menu-open");
            } else {
                TweenLite.to(jQuery("#side_menu_wrapper"), 0.5, {ease: Power4.easeInOut, x: '100%'});

                jQuery("#hamburger-icon").removeClass("closed");
                jQuery("body").addClass("menu-open");
            }
        } else if(action == 'close') {
            jQuery("#side_menu_wrapper").removeClass('open');
            jQuery(jQuery("#mobile-menu li").get().reverse()).each(function(i) { 
                TweenLite.to(jQuery(this), 0.2 * i, { ease: Power4.easeInOut, opacity:0});
            });

            TweenLite.to(jQuery("#side_menu_wrapper"), 0.5, {ease: Power4.easeInOut, x: '0%'});
            
            jQuery("#hamburger-icon").addClass("closed");
            jQuery("body").removeClass("menu-open");
        }
    }

    jQuery('#hamburger-icon-close').click(function() {
        animateMenu(jQuery(this), '');
    });
    
    jQuery("#hamburger-icon").click(function() {
        animateMenu(jQuery(this), '');
    });
    
    // mobile menu scrolling
    jQuery('#scrollable-wrapper').slimScroll({
        height: '100vh',
        alwaysVisible: false
    });

    // jQuery('#side_menu_wrapper').css('top', jQuery('header.banner').height());
    // jQuery(window).resize(function() {
    //     jQuery('#side_menu_wrapper').css('top', jQuery('header.banner').height());
    // });

    // mobile submenu toggle
    jQuery('body').on('click', '#mobile-menu .menu-item-has-children > a', function(e) {
        e.preventDefault();
        var $this = jQuery(this),
            $submenu = $this.siblings('.sub-menu');

        if(!$this.hasClass("expanded")){
            TweenLite.set($submenu, {
                height: "auto",
                opacity: 1,
            });
            TweenLite.from($submenu, 0.5, {
                height: 0,
                opacity: 0,
                ease: Cubic.easeInOut,
            });
            $this.addClass("expanded");
            $submenu.addClass("expanded");
            TweenMax.to($submenu.find('li'), 1, {opacity: 1});
        } else {
            $this.removeClass("expanded");
            $submenu.removeClass("expanded");
            TweenLite.to($submenu, 0.5, {
                height: 0,
                opacity: 0,
                ease: Cubic.easeInOut,
            });
            TweenMax.to($submenu.find('li'), 1, {opacity: 0});
        }
    });
});